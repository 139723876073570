.signin-container {
  .signin-form-container {
    .signin-form-details {
      width: 40%;
      margin-top: 60px;
      margin-bottom: 120px;

      .title {
        color: #2a3a31;
        margin-bottom: 50px;
        display: block;
        text-align: center;
      }

      .google-login-box {
        height: 40px;
        border: 1px solid #e2e4e9;
        border-radius: 4px;
        margin-top: 24px;
      }

      .more-option {
        margin-top: 24px;

        span {
          margin: 0px 10px;
          font-size: 11px;
          line-height: 12px;
          font-weight: 500;
          color: #868c98;
        }

        hr {
          width: 45%;
        }
      }

      .number-input {
        margin-top: 24px;
        .MuiInputBase-root {
          height: 40px;
          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: #1f9151;
            }
          }
        }

        label {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #0a0d14;
          margin-bottom: 4px;

          .validate-icon {
            color: #d91515;
            font-size: 18px;
          }
        }

        .number-textfield {
          .MuiTypography-root {
            color: #868c98;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .term-condition-container {
        margin-top: 15px;

        .MuiCheckbox-root {

          padding: 0px;
          margin-right: 10px;

          &.Mui-checked {
            color: #037f0c;
          }
        }

        .agree-text {
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
          color: #525866;
        }

        a {
          color: #0a0d14;
          text-decoration: underline !important;
          padding-bottom: 5px;
        }
      }
      .sign-up-btn {
        margin-top: 20px;
      }
    }
  }
}

.common-modal {
  &.otp-modal-container {
    width: 400px;
    height: auto;
    padding: 48px 24px;
    border-radius: 12px;

    .title {
      color: #0a0d14;
      margin-bottom: 4px;
    }

    .code-send-number {
      color: #525866;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      strong {
        color: #0a0d14;
        font-weight: 500;
      }
    }

    hr {
      margin: 24px 0px;
    }

    .otp-input-container {
      margin-bottom: 24px;

      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        width: 80px;
        height: 64px;

        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 0px;
          font-size: 24px;
          line-height: 32px;
          font-weight: 500;
          color: #0a0d14;
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
          border: 1px solid #e2e4e9;
          border-radius: 10px;
        }
      }
    }

    .resend-code-container {
      margin-top: 24px;
      font-size: 14px;
      line-height: 20px;

      .code-issue {
        color: #525866;
        font-weight: 400;
        margin-bottom: 4px;
      }

      a {
        color: #0a0d14;
        text-decoration: underline !important;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .signin-container {
    .signin-form-container {
      .signin-form-details {
        width: 50%;
      }
    }
  }
}
@media only screen and (max-width: 950px) {
  .signin-container {
    .signin-form-container {
      .signin-form-details {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .signin-container {
    .signin-form-container .auth_container{
      .signin-form-details {
        width: 100%;
      }
    }
  }
  
}


// new sasss start
.auth_container{
  width: 90%;
max-width: 600px;
height: auto;
padding: 48px 80px;
border-radius: 12px;


.auth_container_title{
  color: #2A3A31;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 36px */
text-align: center;
margin-bottom: 25px;
}

.input_box{
  margin-bottom: 25px;
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #1f9151;
    }
  }

  .terms_and_conditions{
    display: flex;
    align-items: center;
    margin-top: 6px;
    .MuiCheckbox-root {
      padding: 0px;
      margin-right: 10px;

      &.Mui-checked {
        color: #037f0c;
      }
    }
    p{
      color: var(--text-sub-500, #525866);
font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;

/* Label/Small */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: -0.084px;
span{
  color: var(--text-main-900, #0A0D14);
font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
letter-spacing: -0.084px;
text-decoration-line: underline;
}
    }
  }
}

label {
  color: #0a0d14;
  margin-bottom: 4px;
  color: #1C274C;
font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: -0.084px;
}
.otp_labels{
  color: #2A3A31;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
span{
  color: #1F9151;
font-family: Poppins;
cursor: pointer;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 15px */
margin-left: 2px;
}
}
.input_textfield{

}
.otp_box_bottom{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 10px;
  span:nth-child(2){
    color: #2A3A31;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
  }
  span:nth-child(3){
    color: #1F9151;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    cursor: pointer;
  }
}


}


.close_modal{
  position: absolute;
  right: 20px;
  top: 20px;
  width: 16px;
  height: auto;
  cursor: pointer;
}

.input_end_text{
  color: #1F9151;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
cursor: pointer;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
outline: none !important;
}

.MuiInputBase-root {
  height:40px;

  
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #1f9151 !important;
    }
  }}


  .login-btns{
    width:64% !important;
    margin-left: auto  !important;
    margin-right: auto  !important;
  }



  @media screen and (max-width:600px) {
    .auth_container{
      padding: 30px 35px;
    }
  } 
  
  
  @media screen and (max-width:480px) {
    .auth_container{
      padding: 30px 35px;
      padding: 16px 24px; 
    }
  }