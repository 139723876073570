.mycart-container {
  .sub-total-container {
    margin-top: 50px;
    padding: 20px;
    background: #1f91510d;
    border-radius: 8px;

    .left-part {
      h1 {
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
        color: #2a3a31;
        margin-bottom: 5px;
      }

      .sub-heading {
        color: #777777;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }
    }

    .right-part {
      display: inline-flex;
      width: 280px;
      flex-wrap: wrap;
      margin-top: 30px;
      justify-content: flex-start;

      .sub-total {
        color: #000000;

        .title {
          line-height: 24px;
          font-weight: 600;
          font-size: 20px;
          margin-right: 10px;
        }

        .item-count {
          line-height: 24px;
          font-weight: 400;
          font-size: 16px;
          margin-right: 10px;
        }

        .number {
          line-height: 33px;
          font-weight: 600;
          font-size: 22px;
        }
      }

      .saving-amount {
        display: inline-flex;
        align-items: center;
        padding: 10px 15px;
        background-color: #1f91311a;
        border-radius: 4px;
        color: #1f9151;
        font-size: 20px;
        line-height: 24px;
        margin: 8px 0px 16px 0px;

        .title {
          font-weight: 500;
          margin-right: 5px;
        }

        .number {
          font-weight: 600;
        }
      }
      .checkout-btn {
        display: inline-flex;
        width: 100%;
      }
    }
  }

  .cart-item-list {
    margin-top: 30px;
    margin-bottom: 90px;

    .cart-item-title {
      font-size: 16px;
      line-height: 22px;
      color: #707070;
      font-weight: 500;
      padding: 0px 30px;
    }

    .product-cart-container {
      padding: 20px;
      border: 1px solid #d2d2d2;
      background-color: #ffffff;
      margin-top: 20px;
      border-radius: 10px;
      position: relative;

      .product-cart-list {
        .product-cart-img {
          width: 120px;
          margin-right: 10px;
          cursor: pointer;
        }
        .name {
          font-size: 20px;
          line-height: 24px;
          color: #2a3a31;
          font-weight: 500;
          margin-bottom: 5px;
          cursor: pointer;
        }

        .discount-amount {
          font-size: 18px;
          line-height: 27px;
          color: #1a1a1a;
          font-weight: 600;
        }

        .amount {
          font-size: 16px;
          line-height: 27px;
          color: #adadad;
          font-weight: 500;
          margin-left: 8px;
        }
      }

      .bookmark-btn {
        position: absolute;
        right: 5px;
        top: 0;

        svg {
          color: #1f9151;
        }
      }
    }

    .number-input-container {
      display: flex;
      width: 100%;

      .symbol {
        width: 33.33%;
        border: 1px solid #1f9151;
        background-color: #1f9151;
        height: 36px;
        cursor: pointer;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 4px 0px 0px 4px;

        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }

      .Number {
        width: 33.33%;
        border: 1px solid #1f9151;
        background-color: #ffffff;
        height: 36px;
        font-size: 16px;
        line-height: 24px;
        color: #1f9151;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }
    }

    .btn-group {
      margin-top: 15px;

      button {
        color: #797979;
        border-right: 1px solid #797979;
        padding: 0px;
        border-radius: 0px;
        padding-right: 10px;
        margin-right: 10px;
        text-transform: capitalize;
        color: #797979;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        &:last-child {
          border-right: none;
          padding-right: 0px;
          margin-right: 0px;
        }
      }
    }

    .sub-total {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .final-amount {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #1a1a1a;
      }

      .save-amount {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 22px;
        color: #1f9151;
        font-weight: 500;

        strong {
          margin-left: 5px;
          line-height: 24px;
          font-weight: 500;
        }
      }
    }
  }

  
}

@media only screen and (max-width: 900px) {
  .mycart-container {
    .sub-total-container {
      flex-wrap: wrap;
      .left-part {
        width: 100%;
      }
    }
    .cart-item-list {
      .cart-item-title {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mycart-container {
    .cart-item-list {
      .sub-total {
        margin-top: 15px;
        .final-amount {
          justify-content: flex-start;
        }
        .save-amount {
          justify-content: flex-start;
        }
      }
    }
  }
}
