.address-container {
  margin: 50px 0px 90px 0px;
  display: flex;
  justify-content: center;

  .address-stepper-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    .MuiStepConnector-root {
      margin-top: 3px;
      .MuiStepConnector-line {
        width: 16px;
        height: 16px;
        border: none;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-image: url("../../../../img/right-arrow.png");
      }
    }
    .step {
      padding-left: 30px;
      padding-right: 30px;

      .step-label {
        flex-direction: inherit;
        .MuiStepLabel-iconContainer {
          margin-right: 10px;
          .Mui-active {
            color: #ffffff;
            border-radius: 50%;
            border: 1px dashed #1f9151;
            .MuiStepIcon-text {
              fill: #1f9151;
            }
          }
          .Mui-completed {
            color: #1f9151;
          }
        }
        .MuiStepLabel-labelContainer {
          margin-top: 0;
          white-space: nowrap;
          position: relative;
          .Mui-active {
            color: #1f9151;
          }
          .Mui-completed {
            color: #1f9151;
          }
          .outline-common-btn {
            position: absolute;
            left: 0;
            bottom: -25px;
            height: 22px;
            font-size: 10px;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .address-select-container {
    width: 100%;
    justify-content: center;
    display: flex;

    .address-details {
      width: 85%;

      .contain {
        margin-bottom: 40px;

        h2 {
          font-weight: 500;
          color: #2a3a31;
          margin-bottom: 4px;
        }

        p {
          font-size: 18px;
          line-height: 18px;
          color: #969696;
          font-weight: 400;
        }
      }

      .address-card-container {
        padding: 18px 20px;
        border: 1px solid #d6d6d6;
        border-radius: 16px;
        position: relative;
        cursor: pointer;
        min-height: 320px;
        opacity: 1;
        transform: translate3d(0, 0px, 0) scale(1);

        &.active {
          border: 1px solid #1f9151;
          box-shadow: 0px 4px 24px 0px #8e8e8e40;
        }

        .active-check {
          height: 36px;
          width: 36px;
          background-color: #1f9151;
          color: #ffffff;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -15px;
          top: -15px;
        }

        .address-btn {
          height: 32px;
          width: 32px;
          border-radius: 100%;
          margin-right: 12px;
          border: 1px solid #303030;
          color: #303030;

          svg {
            font-size: 18px;
          }

          &:last-child {
            margin-right: 0px;
          }

          &.active {
            border: 1px solid #1f9151;
            color: #1f9151;
          }
        }

        .person-name {
          margin-top: 22px;
          color: #303030;
        }

        address {
          color: #6d6d6d;
          font-size: 16px;
          line-height: 20px;
          margin: 14px 0px 26px 0px;
        }

        .contact-number {
          .contact-heading {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            color: #303030;
            margin-bottom: 8px;
          }

          .title {
            width: 80px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: #3c3c3c;
          }

          .details {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: #6d6d6d;
          }
        }

        .MuiCheckbox-root {
          color: #1f9151;

          &.Mui-checked {
            color: #1f9151;
            border-color: #1f9151;
          }
        }

        .MuiTypography-root {
          font-size: 12px;
          line-height: 15px;
          color: #b1b1b1;
          font-weight: 500;
        }
      }

      .proceed-btn {
        width: auto;
        margin-top: 28px;
      }
    }
  }

  .select-delivery-option-container {
    .delivery-option-details {
      padding: 42px 24px 20px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;

      h3 {
        font-weight: 500;
        margin-bottom: 32px;
      }

      .delivery-inner-box {
        border: 1px solid #e0e0e0;
        padding: 68px 52px 20px 34px;
        box-shadow: 0px 4px 12px 0px #b6b6b640;
        border-radius: 8px;

        .product-img-box {
          width: 64px;
          height: 64px;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 22px;
          cursor: pointer;

          img {
            width: 50px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        .view-all-img-box {
          width: 72px;
          height: 64px;
          border: 1px dashed #2a3a31cc;
          background-color: #1f91511a;
          color: #1f9151;
          font-size: 12px;
          line-height: 17px;
          font-weight: 500;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;
        }

        .delivery-slot-select {
          height: 86px;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          display: flex;
          align-items: center;
          padding: 0px 29px 0px 18px;
          margin-top: 36px;

          .title {
            font-size: 18px;
            line-height: 18px;
            color: #2a3a31;
            font-weight: 500;
            border-right: 1px solid #e0e0e0;
            height: 100%;
            display: flex;
            align-items: center;
            padding-right: 19px;
            margin-right: 30px;
            width: 250px;
          }

          .time-icon {
            font-size: 28px;
            margin-right: 7px;
            color: #2a3a31;
          }

          .slot-time {
            font-size: 18px;
            line-height: 18px;
            color: #2a3a31;
            font-weight: 500;
          }

          .down-arrow-icon {
            color: #141b34;
          }
        }

        .proceed-payment-btn {
          width: auto;
          margin-top: 28px;
        }
      }
    }

    .order-summary-container {
      padding: 24px 16px 13px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;

      .order-title {
        color: #2a3a31;
        font-weight: 500;
        padding-bottom: 28px;
        margin-bottom: 40px;
        border-bottom: 1px solid #e0e0e0;
      }

      .order-summary {
        display: block;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        max-height: 118px;
        padding-right: 5px;
      }

      .product-list {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 17px;
        margin-bottom: 32px;

        .product-name {
          color: #2a3a31;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
        }

        .product-weight {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #1a1a1a;
        }
      }

      .total-amount {
        padding-top: 33px;

        .heading {
          font-size: 18px;
          line-height: 18px;
          color: #2a3a31;
          font-weight: 500;
        }

        .amount {
          font-size: 22px;
          line-height: 33px;
          font-weight: 600;
          color: #1a1a1a;
        }
      }

      .total-saving {
        padding: 8px 13px;
        background-color: #1f91511a;
        margin: 16px 0px 25px 0px;

        .heading {
          font-size: 16px;
          line-height: 16px;
          color: #1f9151;
          font-weight: 500;
        }

        .amount {
          font-size: 18px;
          line-height: 27px;
          font-weight: 600;
          color: #1f9151;
        }
      }

      .information {
        padding: 8px 16px;
        background-color: #fffae6;

        .info-icon {
          color: #141b34;
          margin-right: 12px;
        }

        .text {
          color: #000000;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }
      }
    }
  }

  .payment-option-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .payment-details {
      width: 45%;
      margin-left: 118px;
    }

    .payment-option-box {
      padding: 16px 12px;
      border: 0.78px solid #e4e7ec;
      background-color: #ffffff;
      border-radius: 6px;
      margin-bottom: 16px;

      &.active {
        box-shadow: 0px 6px 20px 0px #4c775f26;
        border: 1px solid #1f915180;
      }

      .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
        padding: 0px;
        margin-right: 12px;

        &.Mui-checked {
          color: #1f9151;
        }
      }

      .payment-title {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: #2a3a31;
      }

      .info {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #2a3a31;
        opacity: 70%;
      }

      .card-type-img {
        width: 36px;
        height: 25px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border: 1px solid #f2f4f7;
        border-radius: 5px;

        &:last-child {
          margin-right: 0px;
        }
      }

      .form-field-title {
        font-size: 14px;
        line-height: 20px;
        color: #353535;
        font-weight: 500;
        margin-bottom: 8px;
      }

      .form-text-field {
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 0px 16px;
          height: 46px;
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
          border: 1px solid #cacaca;
          border-radius: 8px;
        }

        .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 0px 16px;
          height: 46px;
        }
      }
    }

    .place-order-btn {
      width: 180px;
      margin-top: 28px;
    }
  }
}

.common-modal {
  &.select-slot-modal-container {
    width: 900px;
    height: 460px;

    .modal-header {
      height: 70px;
      padding: 0px 32px;
      background-color: #ededed;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      align-items: center;

      .modal-title {
        font-weight: 500;
        color: #000000;
      }

      .modal-close-icon {
        color: #141b34;
      }
    }

    .modal-body {
      .days-slot-tab {
        padding: 24px 32px 15px;

        .MuiTabs-scroller {
          overflow-x: auto !important;
          padding-bottom: 10px;
        }

        button {
          height: 42px;
          border: 1px solid #cdcdcd;
          border-radius: 4px;
          font-size: 18px;
          line-height: 18px;
          font-weight: 500;
          color: #5a5a5a;
          text-transform: capitalize;
          margin-right: 27px;
          padding: 24px 12px;

          &.Mui-selected {
            background-color: #1f91511a;
            border: none;
            color: #1f9151;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        .MuiTabs-indicator {
          display: none;
        }
      }

      hr {
        color: #aeaeae;
      }

      .slot-timing-tab {
        padding: 0px 32px;
        background-color: #edededcc;
        opacity: 80%;

        .MuiTabs-scroller {
          overflow-x: auto !important;
        }

        button {
          padding: 0px;
          margin-right: 25px;
          text-transform: capitalize;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          color: #1a1a1a;

          &:last-child {
            margin-right: 0px;
          }

          &.Mui-selected {
            color: #000000;
            font-weight: 600;
          }
        }

        .MuiTabs-indicator {
          background-color: #1a1a1a;
        }
      }

      .time-slot-checkbox {
        margin-top: 41px;
        padding: 0px 32px;

        .MuiFormControlLabel-root {
          margin-right: 0px;
          height: 48px;
          border: 1px solid #1f9151;
          width: 100%;

          .MuiTypography-root {
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            color: #000000;
          }

          .MuiRadio-root {
            color: #bebebe;

            &.Mui-checked {
              color: #1f9151;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 510px) {
  .address-container {
    .select-delivery-option-container {
      .product-img-box {
        margin-bottom: 12px;
      }

      .delivery-option-details {
        padding: 24px !important;

        .delivery-inner-box {
          padding: 16px !important;
        }
      }

      .total-amount {
        .heading {
          font-size: 14px !important;
        }

        .amount {
          font-size: 18px !important;
        }
      }
    }
    .address-stepper-container {
      .MuiStepper-root {
        flex-wrap: wrap;
        flex-direction: column;
      }
      .MuiStepConnector-root {
        margin-left: -145px;
        transform: rotate(90deg);
      }
      .step {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 968px) {
  .common-modal {
    &.select-slot-modal-container {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
    }
  }

  .address-container {
    .payment-option-container {
      .payment-details {
        width: 80% !important;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .address-container {
    .select-delivery-option-container {
      .delivery-option-details {
        .delivery-inner-box {
          .delivery-slot-select {
            width: 100%;

            > div {
              justify-content: end !important;

              > div {
                display: none !important;
              }
            }

            .title {
              border-right: none;
              width: 100% !important;
            }
          }
        }
      }
    }

    .payment-option-container {
      .payment-details {
        width: 100% !important;
        margin-left: 0px !important;
      }
    }
    
  }
}

@media only screen and (max-width: 1200px) {
  .address-container {
    .payment-option-container {
      .payment-details {
        width: 60%;
      }
    }
  }
}


.iconsscontainer{
  display: flex;
  // justify-content: flex-end;
  // align-items: center;
  // width: 20%;
  gap: 14px;
}

.maindivfortheaddress{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E6e6e6;
  margin-bottom: 10px;
  // margin-top: 26px;


}

.containeradddress{
  padding: 16px;
  width: 100%;
}

.iconsheightwidth{
height: 20px;
width: 20px;
margin-top: 2px;
cursor: pointer;
}

.addresscontainerbox {
  width: 100%;
  height: fit-content;
  margin-bottom: 10px;
  font-size: 14px;
}

.headercontainer{
  display: flex;
  justify-content: space-between;
}

.headeroftheaddress{
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-thickness: 2px; /* Adjust the thickness */
  text-decoration-color: #1f9151; /* Optional: Change the underline color */
  color: #1f9151;
}

.headerboxoftheaddress {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center; // Vertically center the image and header text
    gap: 8px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

.buttonheightwidth {
  height: 32px;
  width: 120px;
  font-size: 14px;
  background-color: #1f9151;
  color: white;
  border-radius: 6px;
  border-color: #1f9151;
  border: none;
  cursor: pointer;
}

.box2container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  padding: 20px;
  height: 50vh;
  width: 100%;
  p{
    font-size: 16px;
    font-weight: 600;
  }
}