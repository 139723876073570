.payment-details-box {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  display: inline-block;
  margin-bottom: 8px;
}
.payment-method {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.grid-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.invoice-title {
  font-size: 16px;
  margin-bottom: 0;
}

.payment-details-box {
  padding: 8px 16px; /* Padding inside the box */
  border: 1px solid #ccc; /* Border around the box */
  border-radius: 4px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background color */
  display: inline-block; /* Adjust box width */
  margin-bottom: 8px; /* Spacing below the box */
}

.payment-method {
  font-size: 16px;
  font-weight: 500; /* Semi-bold text */
  margin-bottom: 0;
}
.invoice-content {
  position: absolute;
  top: -9999px; 
  left: -9999px; 
  width: 960px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
}


.watermark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
  font-size: 54px;
  font-style: italic;
  font-weight: 700;
  color: rgba(0, 95, 65, 0.6);
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
}

.header1 {
  display: flex;
  align-items: center;
  gap: 12px;
  text-align: center;
}

.header1 img {
  width: 40px;
  height: 40px;
}

.header1 h1 {
  margin: 0;
  font-weight: bold;
}

.header1 p {
  margin: 0;
  font-size: 14px;
}

.divider {
  border-bottom: 1.5px dashed black;
  margin: 8px 0;
}

.details {
  display: flex;
  justify-content: space-between;
}

.details .left,
.details .right {
  width: 47%;
}


.details .left strong,
.details .right strong {
  display: inline-block;
  width: 40%;
}

.details p {
  margin: 5px 0;
}

.items {
  margin-top: 20px;
}

.items table {
  width: 100%;
  border-collapse: collapse;
}
/* styles.css */

.logo-container {
  position: relative;
  z-index: 1;
}

.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px; /* Space between the logo and the text */
}

.logo-img {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Keep aspect ratio */
}

.text-box {
  text-align: center; /* Centers the text within its own box */
}

.main-title {
  font-weight: bold;
  margin: 0; /* Remove margin to help with alignment */
  line-height: 1.5; /* Adjust line height for tighter spacing */
}

.subtitle {
  margin: 0; /* Remove margin for subtitle */
  line-height: 1; /* Adjust line height for tighter spacing */
}
.items td {
  padding: 10px;

}


.items tr {
  padding: 40px;
  text-align: left;
}
/* Apply gray background to even rows */
.items tr:nth-child(even) {
background-color: #f3f3f3; /* Light gray */
}

/* Optional: Reset the background for odd rows */
.items tr:nth-child(odd) {
background-color: #ffffff; /* White */
}

.items th {
  padding: 10px;
  text-align: left;
  background-color: #f5f5f5;
}

.summary {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.summary p {
  margin: 5px 0;
}


.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.footer-left,
.footer-right {
    display: inline-block;
}

.footer-content {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.dashed-line {
    flex: 1;
    border-bottom: 1.5px dashed black;
}

.text-spacing {
    margin: 0 8px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

footer p {
    text-align: center;
    margin: 10px 0;
}


.order-placed-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0px 120px 0px;

  .order-placed-message {
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .rating {
      margin: 28px 0px 16px 0px;

      .rating-info {
        font-size: 14px;
        line-height: 28px;
        color: #454545;
        font-weight: 500;
        margin-left: 16px;
      }
    }

    .title {
      font-weight: 600;
      margin-bottom: 12px;
    }

    .thank-you-message {
      font-size: 14px;
      line-height: 24px;
      color: #4b4e4c80;
      width: 80%;

      strong {
        color: #161d25;
      }
    }

    .order-btn {
      margin-top: 28px;

      .back-shopping-btn {
        border: none !important;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        width: auto;
        margin-right: 20px;

        svg {
          font-size: 12px !important;
        }
      }

      .trach-order-btn {
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .order-placed-container {
    .order-placed-message {
      width: 100%;
      .thank-you-message {
        width: 100%;
        text-align: justify;
      }

      .order-btn {
        display: block;
      }

      .back-shopping-btn {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 976px) {
  .order-placed-container {
    .order-placed-message {
      width: 75%;
      .back-shopping-btn {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .order-placed-container {
    .order-placed-message {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .order-placed-container {
    .order-placed-message {
      .rating {
        display: block;
      }
    }
  }
}

.order_placed_container{
  padding-top: 80px;
}

.order_placed_box{
  display: flex;
  gap: 18px;
  margin-bottom: 20px;
  img{
    width: 75.25px;
height: auto;
  }

  h2{
    font-family: Montserrat;
font-size: 24px;
font-weight: 700;
line-height: 29.26px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1F9151;

  }
}

.rating-info{
  font-family: Montserrat;
font-size: 18px;
font-weight: 600;
line-height: 21.94px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
margin-right: 18px;

}

.Delivery_details_box{
background: var(--black-5, #1C1C1C0D);
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
padding: 40px;
gap: 20px;

.select_delivery_address{
background-color: #fff;
padding: 20px;
display: flex;
align-items: center;
gap: 20px;
width: 100%;
.line{
  width: 0px;
height: 62px;
top: 26px;
left: 251px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
border: 1px solid #000000
}
span{
  font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;

}
.span_lite{
  font-family: Poppins;
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
display: flex;
align-items: center;justify-content: start;
gap: 20px;
width: 300px;
img{
  width: 50px;
height: auto;
}
}
}
}

.payment_details_box{
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

.payment_details{
  display: flex;
  flex-direction: column;
  div{
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
display: flex;
justify-content: space-between;
gap: 20px;
.cod{
  font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #D91515;
 width: 100px;

}
.amount{
  color: var(--black-100, #1C1C1C);
  width: 100px;
}
  }
}  
}

@media screen and (max-width:800px) {
.order_placed_box{
  gap: 10px;
  img{
    width: 70px;
  }
}

.rating_box{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
  .order_placed_container{
    padding-top: 150px;
  }

  .Delivery_details_box{
    padding: 14px;
  }
  .Delivery_details_box .select_delivery_address{
    gap: 8px;
    flex-direction: column;
    align-items: start; 
    height: auto;
    .line{
      display: none;
    }
    
 .span_lite{
  img{
    width: 30px;
    gap: 0px;
  }
 }
  }

  .payment_details_box{
    justify-content: start;
  }
}

// SCSS file for responsive font size
.order_placed_container {
  h2 {
    font-size: 2rem; // Default font size for larger screens
    @media (max-width: 768px) {
      font-size: 1.4rem; // Smaller font size for small screens
    }
  }

  .rating-info {
    font-size: 1.4rem; // Default font size for larger screens
    @media (max-width: 768px) {
      font-size: 0.9rem; // Smaller font size for small screens
    }
  }

  .select_delivery_address span {
    font-size: 1.2rem;
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
}
.order_placed_container {
  h3 {
    font-size: 1.2rem; // Default font size for larger screens
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 1rem; // Smaller font size for small screens
    }
  }
}

.payment_details {
  span {
    font-size: 1.2rem; // Default font size for larger screens
    @media (max-width: 768px) {
      font-size: 0.9rem; // Adjust font size for small screens
    }
  }
}


