.cart_popup{
    width: 469px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 17px;
position: relative;
height: 100vh;
    // my cart
    .my_cart_container{
       
    .my_cart{
        position: sticky;
display: flex;
margin: -20px -20px -0px -20px;
justify-content: space-between;
padding-left: 21px;
    padding-right: 21px;
    padding-top: 17px;
    padding-bottom: 17px;
    background-color: #fff;
    top: 0px;
    z-index: 1300;
h2{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 21.6px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
}
img{
    width: 14px;
    cursor: pointer;
}
    }
    .select_delivery_slot{
        margin-top: 18px;
        z-index: 20;
        .select_delivery_slot_wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
           
            .select_slot_btn{
                background: #00B2071A;
                
width: 141px;
padding: 10px ;
height: 44px;
gap: 10px;
border-radius: 10px;
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1F9151;
z-index: 11;


            }
        }
        h2{
            font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
        }
        div{
            margin-top: 12px;
            width: 100%;
height: 60px;
cursor: pointer;
padding: 19px 12px 17px 12px;
border-radius: 9px;
border: 1px solid #2ecf9f;
display: flex;
align-items: center;
justify-content: space-between;

span{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #192534;

}
&.slot-error {
    border: 1px solid red !important;
  }
    

        }
    }

    .item_details_container{
        margin-top: 18px;
        h2{
            font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
        }
        .items_container{
        //  min-height: 310px;
 .cart_item{
    z-index: 1;
    display: flex;
    align-items: center;
    margin-top: 16px;
    .img_box{
cursor: pointer;
    }
    .item_details{
        span:nth-child(1){
            display: block;
            font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 16.8px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
        }
        span:nth-child(2){
            display: block;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 500;
            line-height: 14.4px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: var(--black-40, #1C1C1C66);
        }
        div{
            .price {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #2D2D2D;
                display: flex;
                align-items: center;
              }
              
              .mrp {
                font-size: 13px;
                font-weight: 400;
                line-height: 19.5px;
                text-decoration: line-through;
                color: #808080;
                margin-left: 5px;
                display: flex;
                align-items: center;
              }
                      }
    }
 }


        }
    }


  
    .my_cart_bottom{

        margin-top: 20px;
        padding: 20px;
        // width: 100%;
height: 187px;
border-radius: 14px 14px 0px 0px;

box-shadow: 0px 1px 4px 0px #00000040;
// position: absolute;

bottom: 0;
right: 0;
left: 0;
z-index: 1000;
background-color: #fff;
.my_cart_bottom_address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:  1px solid var(--black-10, #1C1C1C1A);
    padding-bottom: 20px;
    img{
        width: 32px;
    }
    div{

        flex-grow: 1;
        margin-left: 14px;
        span:nth-child(1){
            display: block;
            font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 21.6px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
        }
        span:nth-child(2){
            display: block;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: var(--black-40, #1C1C1C66);
            margin-top: 5px;
        }
       
    }
     a{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        
        }
}
    }
   
    .pay_now_btn{
        border-radius: 20px;
        gap: 10px;
       margin-top: 24px;

       //styleName: Body Medium/Body Medium, 600;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;

    }
}


// // Select Delivery Address
.select_delivery_address_container{

.select_delivery_address{
    display: flex;
    align-items: center;
    
    img{
        width: 20px;
        cursor: pointer;
    }
    h2{
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 21.6px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);

    }
}

.select_delivery_slot{
    margin-top: 20px;
    h2{
        margin-bottom: 13px;
        font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);

    }
.add_new_address_btn{
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
height: 60px;
padding: 19px 238px 17px 12px;
gap: 0px;
border-radius: 9px;
background: #00B2071A;
span{
    font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1F9151;
margin-left: 10px;
}

img{
    width: 20px;
}

}

}

.delivery_slots_container{
    margin-top: 18px;
    h2{
        font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 16.8px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
    }

}
}

}

.delevery_slot_modal{
  
.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-family: Poppins !important;
font-size: 14px !important;
font-weight: 600 !important;
text-transform: none !important;
line-height: 21px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #1F9151 !important;

}
.css-1ujykiq-MuiButtonBase-root-MuiTab-root{
    text-transform: none !important;

    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: #A09797 !important;
}

.css-1ujykiq-MuiButtonBase-root-MuiTab-root{
padding-left: 0px !important;
padding-right: 0px !important;
}
.css-heg063-MuiTabs-flexContainer {
    display:block !important;
}
.css-e784if-MuiTypography-root{
//styleName: Form/Default;
font-family: Open Sans !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 22px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #16191F !important;
}
padding: 0.8rem; /* Internal padding */
max-width: 470px;
width: 90%;
height: 420px;
border-radius: 16px;
border: 1px solid #ccc; /* Add a visible border */
background-color: #fff; /* Ensure a consistent background */
overflow-y: auto; /* Enables vertical scrolling if content overflows */
display: flex;
flex-direction: column; /* Allows content to stack vertically */
scrollbar-width: none; /* Hides scrollbar for Firefox */
-ms-overflow-style: none; /* Hides scrollbar for IE/Edge */

/* Optional: additional fallback to hide scrollbars in Webkit browsers */
&::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and other Webkit-based browsers */
}
            .delevery_slot_modal:focus {
        outline: none; /* Optional: removes outline when focused */
      }
      
  .delevery_slot_modal > .delevery_slot_modal_top {
    flex-shrink: 0; /* Ensure header doesn't shrink */
  }
  
  .delevery_slot_modal > .modal-content {
    flex-grow: 1; /* Allow content to take remaining space */
    overflow-y: auto; /* Enable vertical scrolling for content */
    padding-right: 8px; /* Space for scrollbar */
  }
  
   .delevery_slot_modal_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
            font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 33px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
        }
        img{
            width: 15px;
            cursor: pointer;
        }
    }
    .delevery_slot_modal_days{
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        span{      
padding: 8px 16px 8px 16px;
cursor: pointer;
// background: #1F91511A;
border-radius: 100px;
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 16.8px;
letter-spacing: 0.08em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-40, #1C1C1C66);

        }
        .active_day{  
cursor: pointer;
padding: 8px 16px 8px 16px;
background: #1F91511A;
border-radius: 100px;
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 16.8px;
letter-spacing: 0.08em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1F9151;
        }
    }
    
}

.select_your_address_popup{
    max-width: 800px;
    width:90% ;
// height: 545px;
gap: 24px;
display: flex;
flex-direction: column;
border-radius: 12px ;
background: #FFFFFF;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
padding-bottom: 20px;
@media screen and 
(max-width:800px) {
    .select_your_address_popup{
        display: none;
    }

}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected , .css-1xfw5x-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #1F9151 !important;
    text-transform: none  !important;
    
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    

}
.css-1xfw5x-MuiButtonBase-root-MuiTab-root , .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: none  !important;
    font-family: Poppins !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: 24px !important;
text-align: left;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #2A3A31 !important;

}

h2{
    font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #2A3A31;
}
.address_container{
    width: 100%;
height: 103px;
border-radius: 8px ;
background: #1F91511A;
display: flex;
flex-direction: column;
align-items: start;
justify-content: center;
gap: 10px;
padding: 20px;
display: flex;
span{
    display: flex;
    align-items: center;
    gap: 17px;
    img{
        width: 24px;
    }
}
 span:nth-child(1){
    img{

    }
    span{
        font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

color: #1F9151;

    }
 }
 span:nth-child(2){
    img{

    }
    span{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #353535;        
    }
  
 }
}
}

@media screen and 
(max-width:800px) {
    .select_your_address_popup{
       width: 100%;
      
    }
}
@media screen and (max-width:800px) {
   
   
    .cart_popup{
       width: 100vw;

       .my_cart{
        padding-left: 21px;
    padding-right: 21px;
    padding-top: 17px;
    
    // margin-left: -21px;
    // margin-right: -21px;
    // margin-top: -17px;
        position: sticky;
        height: 56px;
        align-items: center;
        padding: 21px;
        top: 0;
        left: 0;
        right: 0;
        background-color: #005F41 !important;
        z-index: 2000;
        h2{
            color: #FFFFFF !important;
        }
       }
       .select_delivery_slot{
        .select_slot_btn{
            display: flex;
            // background-color: red;
        }
       }
    }
    
}





// Bottom Popups ///////////////////

.css-9emuhu-MuiPaper-root-MuiDrawer-paper{
    background-color: transparent !important;
}

.tab_popup_new_address{
    width: 100%;  
 height: 80vh;
 border-radius:12px 12px 0px 0px ;
    // padding: 24px 24px 24px 24px;
    gap: 0px;
    background-color: #fff;
}
.tab_popup{ 
    width: 100%;
max-height: 80vh;
min-height: 50vh;
gap: 0px;
padding: 1rem;
 background-color: #fff;

 box-shadow: 0px 12px 16px 0px rgba(16, 24, 40, 0.0784313725);
 padding: 0.8rem;
 border-radius: 8px;
 height: fit-content;
 max-height: 80vh;
 overflow-y: auto;

.tab_select_delivery_container{
   h2{
    font-family: Poppins;
font-size: 16px;
margin-bottom: 12px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
   }
}
.tab_address_btn{
   margin-top: 15px;
    width: 100%;
height: 60px;
padding-left: 19px ;
display: flex;
align-items: center;
justify-content: start;
gap: 10px;
border-radius: 9px ;
background: #00B2071A;

font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #1F9151;

img{
    width: 17px;
}
   }

   .empty_address{
    margin-top: 15px;
    width: 100%;
height: 240px;

border-radius: 10px;

background: var(--black-5, #1C1C1C0D);
border: 1px dashed #1C1C1C66;

font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 19.2px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-40, #1C1C1C66);
display: flex;
align-items: center;
justify-content: center;

   }
}

.tab_select_your_address{
    font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}


// temporyyy
.select_address_labels{
    font-family: Roboto;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #353535;
margin-bottom: 8px;
}


.empty_address{
    margin-top: 15px;
    width: 100%;
height: 240px;
border-radius: 10px;
background: var(--black-5, #1C1C1C0D);
border: 1px dashed #1C1C1C66;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 19.2px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-40, #1C1C1C66);
display: flex;
align-items: center;
justify-content: center;
   }
   

//    address box
.address_box_active{
    border: 2px solid #1F9151 !important;
        }
        .address_box{
            cursor: pointer;    
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            width: 100%;
            margin-top: 15px;
    // height: 119px;
    border-radius: 10px;
    border: 1px dashed var(--black-20, #1C1C1C33);
    
    div:nth-child(1){
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:nth-child(1){
            font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
        }
        span:nth-child(2){
            width: 61px;
    padding: 10px ;
    height: 26px;
    border-radius: 12px;
    background: #1F9151;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 600;
    line-height: 13.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    
        }
    }
    p{
        font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--black-80, #1C1C1CCC);
    
    }
    .address_box_bottom{
        display: flex;
        align-items: center;
        gap: 10px;
        img{
            width: 16px;
            cursor: pointer;
        }
    }
        }


        .not_common_modal{
            // position: static;
           
        }

        @media screen and (min-width:800px) {
            .not_common_modal{
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: #ffffff;
                transform: translate(-50%, -50%);
                outline: none;
            }
        }



        @media screen and (max-width:800px) {
            .my_cart_bottom{
    position: fixed;
                margin-top: 20px;
                padding: 20px;
                // width: 100%;
        height: 187px;
        border-radius: 14px 14px 0px 0px;
        box-shadow: 0px 1px 4px 0px #00000040;
        // position: absolute;
        
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;
        background-color: #fff;
        .my_cart_bottom_address{
            margin-top: 40px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom:  1px solid var(--black-10, #1C1C1C1A);
            padding-bottom: 20px;
           
        }
            }
        }


        .space_adder {
            height: 20px;
            width: 100%;
          
        }

        .my_cart_bottom_address{
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // border-bottom:  1px solid var(--black-10, #1C1C1C1A);
            // padding-bottom: 20px;
            img{
                width: 32px;
            }
            div{
        
                flex-grow: 1;
                margin-left: 14px;
                span:nth-child(1){
                    display: block;
                    font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
                }
                span:nth-child(2){
                    display: block;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: var(--black-40, #1C1C1C66);
                    margin-top: 5px;
                }
               
            }
             a{
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                text-align: left;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                
                }
        }
        @media screen and (max-width:800px) {
            .space_adder {
                height: 200px;
                width: 100%;
             
            }
            .cart_popup .my_cart_container .select_delivery_slot div {
                height: 50px;
            }
            
        }


        @media screen and (max-width:600px) {
            .cart_popup .my_cart_container .select_delivery_slot div {
                margin-top: 12px;
                width: 100%;
                cursor: pointer;
                padding: 19px 12px 17px 12px;
                border-radius: 9px;
                border: 1px solid #3bd488;
                display: flex;
                align-items: center;
                font-size: 13px;
                justify-content: space-between;
            
                &.slot-error {
                    border: 1px solid red !important;
                }
            }
                        .cart_popup .my_cart_container .select_delivery_slot .select_delivery_slot_wrapper .select_slot_btn{
                display: none;
            }
            .cart_popup .my_cart_container .select_delivery_slot div img{
                display: none;
            }
            .cart_popup .my_cart_container .select_delivery_slot div span{
                font-size: 14px;
            }
        }



        .Payment_methods_box{
            margin-top: 17px;
            h2{
                margin-bottom: 15px;
                font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: var(--black-80, #1C1C1CCC);
            }
            div{
                margin-top: 12px;
                width: 100%;
                height: 60px;
                cursor: pointer;
                padding: 19px 12px 17px 12px;
                border-radius: 9px;
                border: 1px solid #CACACA;
                display: flex
            ;
                align-items: center;
                justify-content: space-between;
span{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
            }            
        }


        .bill_details{
            margin-top: 20px;
            padding: 12px;
            width: 100%;
    height: 150px;
    border-radius: 16px;
    background: #00B2071A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    strong{
        font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    }
    
    div{
        display: flex;
        justify-content: space-between;
        span{
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
        }
        .mrp{
            font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-decoration-line: line-through;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    color: #808080;
    
        }
        .free{
            font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1F9151;
    margin-left: 5px;
        }
        strong{
            font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2D2D2D;
    
        }
    }
        }
        .boxdecor {
            padding: 20px;
          }
          

.box{
    justify-content: center;
    display: flex;
    align-items: center;
}

.circularprogressstyle{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 10px;
}


.noslot{
    justify-content: center;
    display: flex;
    align-items: center;
}


.payment_container{
    bottom: 0px;
    left: 0;
    width: 100%;
 

    position: sticky;
    background-color: #fff;
    padding-top: 1px;
    
}


@media screen and (max-width:800px) {
    .Payment_methods_box div{
        height: 50px;
    }
}


.mob_placed_order_btn{
    background-color: #005F41;
}