.contact-heading-banner {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 220px;
  margin-top: 0px;
  background-image: url(../../img/contact-heading-banner.png);
  background-position: left 35%;
  background-repeat: no-repeat;
  background-size: cover;
  h2 {
    display: flex;
    width: 100%;
    color: #ffffff;
    font-size:34px;
    line-height: 40px;
    font-weight: 600;
  }
}

.contact-info {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
.links-box {
     display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #bfbfbf;
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 15px 15px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #000000;
      border-bottom: 1px solid #bfbfbf;
    }
    ul {
      display: flex;
      width: 100%;
      // flex-wrap: wrap;
      padding: 10px 0px;
      margin: 0;
      li {
        display: flex;
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
        color: #344054;
        padding: 15px 25px;
        cursor: pointer;
        &.active {
          color: #1b7643;
          background-color: #1f915133;
        }
      }
    }
  }
  .support-details {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .support-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0px 20px 30px;
      border-radius: 5px;
      border: 1px solid #d6d6d6;
      margin-bottom: 20px;
      h4 {
        display: inline-flex;
        width: 50%;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #303030;
        padding-bottom: 5px;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 1px solid #1f9151;
      }
      p {
        display: flex;
        width: 100%;
        color: #6d6d6d;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        a {
          padding-left: 20px;
        }
      }
    }
  }
  .feedback-from {
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    display: flex;
    width: 100%;
    padding: 20px;
    .textfield {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 15px;
      label {
        display: flex;
        width: 100%;
        // padding-bottom: 5px;
        font-weight: 500;
      }
      .input {
        .MuiInputBase-root {
          border-radius: 3px;
          
          &::after,
          &::before {
            display: none;
          }
          input {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
          }
          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: #1f9151;
            }
          }
        }
        &.outlined {
          .MuiInputBase-root {
            background-color: transparent;
          }
        }
        &.textarea {
          .MuiInputBase-root {
            height: auto;
          }
        }
      }
      .select {
        width: 100%;
        > div {
          padding-top: 13px;
          padding-bottom: 13px;
        }
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #1f9151;
          }
        }
      }
      p {
        display: flex;
        width: 100%;
      }
    }
    .submit-btn {
      padding-left: 20px;
      padding-right: 20px;
      height: 38px;
      font-size: 14px;
    }
  }
}

.divcontainer{
  display: flex;
}

.headerboxofthecustomer {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 6px;

  div {
    display: flex;
    align-items: center; // Vertically center the image and header text
    gap: 8px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

.links-boxes {
  // display: inline-flex;
 width: 100%;
 flex-wrap: wrap;
 border-radius: 5px;
 margin-top: 20px;
 margin-bottom: 20px;
 border: 1px solid #bfbfbf;
 h2 {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   padding: 15px 15px;
   font-size: 18px;
   line-height: 20px;
   font-weight: 600;
   color: #000000;
   border-bottom: 1px solid #bfbfbf;
 }
 ul {
  //  display: flex;
   width: 100%;
   // flex-wrap: wrap;
   padding: 10px 0px;
   margin: 0;
   li {
     display: flex;
     width: 100%;
     font-size: 14px;
     line-height: 16px;
     font-weight: 600;
     color: #344054;
     padding: 15px 25px;
     cursor: pointer;
     &.active {
       color: #1b7643;
       background-color: #1f915133;
     }
   }
 }
}


.support-boxes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 20px 30px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  margin-bottom: 20px;
  h4 {
    display: inline-flex;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #303030;
    padding-bottom: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #1f9151;
  }
  p {
    display: flex;
    width: 100%;
    color: #6d6d6d;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    a {
      padding-left: 20px;
    }
  }
}
