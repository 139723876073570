*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: "Mona Sans", sans-serif !important;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a,
a:hover {
  color: #1f9151;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

input:focus-visible {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d9da;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c2c2;
}
.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.d-inline-flex {
  display: inline-flex;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.main-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 70px;
  &.categories {
    padding-top: 160px;
  }
}

button {
  &.common-btn {
    background-color: #1f9151;
    height: 46px;
    border-radius: 6px;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
    font-size: 18px;
    box-shadow: none;
    text-transform: capitalize;

    &:hover {
      background-color: #1f9151;
      box-shadow: none;
    }
  }

  &.outline-common-btn {
    border: 1px solid #1f9151;
    color: #1f9151;
    height: 46px;
    border-radius: 6px;
    text-transform: capitalize;
    box-shadow: none;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;

    &:hover {
      border: 1px solid #1f9151;
      box-shadow: none;
    }
    &.MuiButton-colorError {
      color: #d32f2f;
      border-color: #d32f2f;
    }

    svg {
      font-size: 28px !important;
    }
  }

  .MuiCircularProgress-root {
    width: 14px !important;
    height: 14px !important;
    color: #ffffff;
  }
}

.common-symbol {
  display: flex;
  justify-content: center;

  .outer-symbol {
    width: 88px;
    height: 88px;
    border-radius: 100%;
    background: linear-gradient(
      180deg,
      rgba(31, 145, 81, 0.2) 0%,
      rgba(31, 145, 81, 0) 100%,
      rgba(228, 229, 231, 0) 100%
    );
    border: 1px solid;
    border-image-source: linear-gradient(
      180deg,
      #e4e5e7 0%,
      rgba(228, 229, 231, 0) 76.56%
    );
    padding: 14px;
  }

  .inner-box {
    border: 1px solid var(--stroke-soft-200, #e2e4e9);
    background-color: #ffffff;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.common-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  outline: none;
}

.common-loader {
  height: 20vh;
  svg {
    color: #1f9151;
  }
  &.plus-icon {
    height: 16px !important;
    width: 16px !important;
    svg {
      color: #ffffff;
    }
  }
}

button {
  .common-loader {
    color: #1f9151 !important;
    svg {
      color: #1f9151 !important;
    }
    &.delete {
      
      color: #d32f2f !important;
      svg {
        color: #d32f2f !important;
      }
    }
  }
}

.no-data {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #1a1a1a;
}
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background-color: #1f9151;
    .MuiTooltip-arrow {
      color: #1f9151;
    }
  }
}

.condition-contents-container {
  display: block;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 60px;
  h1 {
    display: block;
    width: 100%;
    color: #000000;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  strong {
    display: block;
    width: 100%;
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  span {
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #989692;
    margin-bottom: 20px;
  }
  p {
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
}

.common-supense-loader {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  svg {
    color: #1f9151;
  }
}

@media (max-width: 600px) {
  .main-container {
    padding-top: 84px;
    &.categories {
      padding-top: 114px;
    }
  }
  .mobile-deliver-box + .categories-container + .main-container {
    padding-top: 124px;
    &.categories {
      padding-top: 154px;
    }
  }
}

.h2spacing{
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.ordercencellationrequest{
  font-weight: 600;
  color: red;
}
.iconmargin{
  margin-bottom: 16px;
}

.ordercopyicon{
cursor: pointer;
}