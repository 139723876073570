.welcome-promode-banner {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 400px;
  margin-top: 0px;
  background-color: #ffd9ac;
  background-image: url(../../img/flat-lay-vegetables-frame.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  .contents {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    h2 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      color: #344054;
      margin-bottom: 15px;
      span {
        display: inline-flex;
        color: #1f9151;
      }
    }
    p {
      display: flex;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #1c274c;
      text-align: justify;
    }
    .story-btn {
      margin-top: 20px;
      font-size: 14px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
.our-story-container {
  display: flex;
  width: 100%;
  padding: 60px 0;
  .heading {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    span {
      display: flex;
      width: 100%;
      color: #1f9151;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    h2 {
      display: flex;
      width: 100%;
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      color: #353535;
    }
  }
  .contents {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 30px;
    p {
      display: flex;
      width: 80%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #000000;
    }
    .ceo-details {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 30px;
      .image {
        display: inline-flex;
        width: 72px;
        height: 72px;
        overflow: hidden;
        border-radius: 50%;
      }
      .name {
        display: inline-flex;
        flex-wrap: wrap;
        width: auto;
        margin-left: 20px;
        strong {
          display: flex;
          width: 100%;
          font-weight: 600;
        }
      }
      .signature {
        display: inline-flex;
        width: 100px;
      }
    }
  }
}
.specialties-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 60px 0;
  background-color: #1f915117;
  background-image: url(../../img/shape-right-top.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  .heading {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    color: #04000b;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
  }
  .specialties-boxes {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    .specialties-box {
      display: inline-flex;
      flex-wrap: wrap;
      width: 25%;
      padding: 30px;
      margin-right: 2px;
      min-height: 400px;
      border-radius: 10px;
      background-color: #ffffff;
      .image {
        display: flex;
        width: 100px;
      }
      .name {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
        h4 {
          display: flex;
          width: 100%;
          text-transform: uppercase;
          color: #04000b;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 5px;
        }
        span {
          display: flex;
          width: 100%;
          text-transform: uppercase;
          color: #49a760;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
      p {
        display: flex;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
}
.our-mission-container {
  display: flex;
  width: 100%;
  padding: 60px 0;
  background-color: #117c40;
  .contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    h2 {
      display: flex;
      text-align: center;
      justify-content: center;
      width: 100%;
      color: #ffffff;
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    p {
      display: flex;
      width: 80%;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.sustainability-container {
  display: flex;
  width: 100%;
  min-height: 460px;
  padding: 60px 0;
  background-color: #f6f6f6;
  background-image: url(../../img/rectangle.jfif);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  .contents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    position: relative;
    .organic-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: absolute;
      left: 0;
      top: -75px;
      width: 100px;
      height: 100px;
      background-color: #f7c35f;
      &::after {
        position: absolute;
        right: -17px;
        top: -2px;
        content: "";
        border-width: 0px 17px 17px 0px;
        border-style: solid;
        border-image-source: linear-gradient(
          36.3deg,
          rgba(247, 195, 95, 0.5) 50%,
          rgba(0, 0, 0, 0) 50%
        );
      }
      strong {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-top: auto;
        font-size: 36px;
        line-height: 40px;
        font-weight: 600;
        color: #04000b;
      }
      span {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-bottom: auto;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: #04000b;
        font-style: italic;
        text-transform: uppercase;
      }
    }
    h2 {
      display: flex;
      width: 100%;
      color: #49a760;
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 15px;
      margin-top: auto;
    }
    p {
      display: flex;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #666666;
      margin-bottom: auto;
      text-align: justify;
    }
  }
}
.our-community-container {
  display: flex;
  width: 100%;
  padding: 60px 0;
  .community-contents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
    .contents {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      span {
        display: flex;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #eec044;
      }
      h2 {
        display: flex;
        width: 100%;
        color: #353535;
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 5px;
      }
      p {
        display: flex;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.joinus-container {
  display: flex;
  width: 100%;
  padding: 60px 0;
  background-color: #1f915117;
  background-image: url(../../img/green-vegetables.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;
  .contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    h2 {
      display: flex;
      text-align: center;
      justify-content: center;
      width: 100%;
      color: #353535;
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    p {
      display: flex;
      width: 100%;
      padding-left: 15%;
      padding-right: 15%;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #303030;
    }
    .join-btn {
      margin-top: 20px;
      font-size: 14px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .welcome-promode-banner {
    .contents {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 900px) {
  .our-story-container {
    .contents {
      padding-left: 0;
    }
  }
  .specialties-container {
    .specialties-boxes {
      .specialties-box {
        width: 33.11%;
      }
    }
  }
}
@media only screen and (max-width: 600px) { 
  .our-story-container {
    .contents {
      p {
        width: 100%;
      }
    }
  }
  .specialties-container {
    .specialties-boxes {
      flex-wrap: wrap;
      .specialties-box {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2px;
      }
    }
  }
  .our-mission-container {
    .contents {
      p {
        width: 100%;
      }
    }
  }
  .sustainability-container {
    background-size: contain;
    .contents {
      width: 100%;
    }
  }
}
