.newsletter-container {
  background-color: #f7f7f7;
  padding-top: 30px;
  padding-bottom: 30px;
  .contents {
    h4 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #1a1a1a;
      margin-bottom: 5px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      color: #999999;
    }
  }
  .newsletter-box {
    display: flex;
    width: 100%;
    position: relative;
    .newsletter {
      width: calc(100% - 0px);
      border-radius: 25px;
      background-color: #ffffff;
      input {
        padding-left: 15px;
        padding-right: 145px;
      }
      fieldset {
        border-radius: 25px;
        border-color: #e6e6e6;
      }
    }
    .common-btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      font-size: 14px;
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 25px;
      background-color: #00b207;
    }
  }
  .socials-links {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    a {
      display: inline-flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-left: 5px;
      border-radius: 50%;
      color: #4d4d4d;
      &:hover {
        color: #ffffff;
        background-color: #00b207;
      }
    }
  }
}

.made-container {
  display: block;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(230, 230, 230, 1);
  h2 {
    display: block;
    font-size: 44px;
    line-height: 60px;
    color: rgba(158, 157, 157, 1);
  }
  span {
    display: block;
    width: 80%;
    text-align: right;
    margin-top: -60px;
    img {
      max-width: 72px;
      
    }
  }
  p {
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 20px;
    color: rgba(158, 157, 157, 1);
  }
}

.footer {
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 0px;
  background-color: #1a1a1a;

  .footer-about-text {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    p {
      margin-top: 1.7em;
      margin-bottom: 1em;
      font-size: 12px;
      line-height: 22px;
      font-weight: 300;
      color: #ffffff;
    }
    .text {
      display: flex;
      color: #808080;
      font-size: 13px;
      line-height: 22px;
      font-weight: 300;
      span {
        padding-left: 10px;
        padding-right: 10px;
      }
      a {
        display: inline-flex;
        color: #ffffff;
        text-decoration: underline;
        border-bottom: 2px solid #20b526;
      }
    }
  }

  .footer-links {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    h3 {
      display: flex;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #ffffff;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        padding-bottom: 3px;

        a {
          font-size: 12px;
          line-height: 22px;
          font-weight: 300;
          color: #999999;
          &:hover {
            color: #ffffff;
          }
        }
      }
    }
  }
  .footer-bottom {
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    border-top: 1px solid #333333;
    .copyright {
      color: #808080;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }
    .cards {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      span {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .newsletter-container {
    .socials-links {
      justify-content: flex-start;
      a {
        margin-right: 5px;
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 900px) {
  .footer {
    .footer-about-text {
      margin-bottom: 20px;
    }
    .footer-bottom {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .footer {
    background-color: rgba(48, 57, 52, 1);
    display: none;
    .footer-about-text {
      .text {
        a {
          background-color: rgba(26, 26, 26, 1);
        }
      }
    }
  }
}