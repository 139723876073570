.sidebar {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .sub-categories {
    display: flex;
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        a {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: #ffffff;
          border-left: 3px solid #ffffff;
          padding: 10px;
          span {
            display: inline-flex;
            width: 32px;
            height: 32px;
            padding: 3px;
            border-radius: 3px;
            background-color: #ffffff;
          }
          strong {
            display: inline-flex;
            width: calc(100% - 32px);
            padding-left: 10px;
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
          }
          &.active {
            border-color: #1f9151;
            background-color: rgba(0, 178, 7, 0.2);
          }
        }
      }
    }
  }
}

.current-category {
  display: none;
}

.listing-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .load-more-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    a {
      display: inline-flex;
      padding: 5px 15px;
      border-radius: 4px;
      border: 1px solid #1f9151;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #1f9151;
      text-transform: capitalize;
    }
  }

  .heading {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    h2 {
      display: flex;
      width: 100%;
      line-height: 28px;
      font-size: 24px;
      font-weight: 600;
      color: #2a3a31;
    }

    .sort-by {
      display: inline-flex;
      align-items: center;
      line-height: 24px;
      margin-right: 15px;
      > div {
        > div {
          border: 1px solid #d2cfcf;
          border-radius: 2px;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }

          select {
            padding: 7px 10px;
            padding-right: 30px;
            font-size: 13px;
            line-height: 17px;
          }

          svg {
            right: 0px;
            top: 4px;
          }
        }
      }
    }

    .results-text {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #666666;

      strong {
        display: inline-flex;
        margin-right: 5px;
      }
    }
  }

  .products {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 0px;

    .product-box {
      display: flex;
      flex-wrap: wrap;
      width: calc(25% - 12px);
      margin-left: 0px;
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 10px;
      border: 1px solid #e6e6e6;
      position: relative;

      &:nth-child(4n + 4) {
        margin-right: 0;
      }

      &.hide {
        opacity: 0.6;
      }

      .sale {
        position: absolute;
        left: 0;
        top: 0;
        width: 90px;
        height: 32px;
        font-size: 14px;
        line-height: 32px;
        font-weight: 400;
        color: #ffffff;
        display: inline-flex;
        justify-content: center;
        text-align: center;
        background-color: #1f9151;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .icon {
        cursor: pointer;
        background-color: #fff;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 24px;
        height: 24px;
        font-size: 13px;
        color: #141b3499;
        font-weight: 400;
        line-height: 24px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border: 1px solid #e5e5e5;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        margin: 0px;
        cursor: pointer;

        img {
          max-height: 150px;
        }
      }

      .name {
        display: flex;
        width: 100%;
        min-height: 40px;
        padding-bottom: 5px;

        a {
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;
          color: #000000;

          &:hover {
            color: #1f9151;
          }
        }
      }

      .select {
        display: flex;
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        color: #958e8e;
        margin-bottom: 10px;
        select {
          width: 100%;
          font-size: 13px;
          line-height: 18px;
          border: 1px solid #cacaca;
          border-radius: 3px;
          padding-left: 3px;
          padding-right: 3px;
          padding-top: 2px;
          padding-bottom: 2px;
          cursor: pointer;
          option {
            cursor: pointer;
          }
        }
      }

      .price-cart {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .price {
          width: 80px;
          display: inline-flex;
          flex-wrap: wrap;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: #1a1a1a;

          strong {
            display: flex;
            width: 100%;
            align-items: center;
            font-size: 14px;
            color: #2d2d2d;
            svg {
              max-width: 14px;
              height: 14px;
            }
          }

          span {
            display: flex;
            align-items: center;
            width: 100%;
            color: #808080;
            font-size: 13px;
            font-weight: 400;
            text-decoration: line-through;
            svg {
              max-width: 12px;
              height: 12px;
            }
          }
        }
        .add-cart {
          display: inline-flex;
          width: 64px;

          button {
            border: 1px solid #1f9151;
            width: calc(100% - 2px);
            padding: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #1f9151;
            text-transform: capitalize;
            height: 36px;
            background-color: rgba(0, 178, 7, 0.1);
            &.Mui-disabled {
              color: rgba(0, 0, 0, 0.26) !important;
              border: 1px solid rgba(0, 0, 0, 0.26) !important;
            }
          }
        }
      }

      .number-input-container {
        display: inline-flex;
        width: 64px;

        .symbol {
          width: 33.33%;
          border: 1px solid #1f9151;
          background-color: #1f9151;
          height: 36px;
          cursor: pointer;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 4px 0px 0px 4px;

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }
        }

        .Number {
          width: 33.33%;
          height: 36px;
          border: 1px solid #1f9151;
          background-color: #1f9151;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 990px) {
  .listing-container {
    .products {
      .product-box {
        width: calc(33.33% - 12px);
        margin-right: 12px;
        &:nth-child(4n + 4) {
          margin-right: 12px;
        }
        &:nth-child(3n + 3) {
          margin-right: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .current-category {
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    font-weight: 500;
    padding: 7px 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .mobile-deliver-box + .categories-container + .main-container {
    .current-category {
      top: 114px;
    }
    .sidebar {
      top: 154px;
    }
  }
  .sidebar {
    position: fixed;
    top: 114px;
    left: 0;
    width: 90px;
    z-index: 10;
    align-items: flex-start;
    height: calc(100% - 0px);
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    .sub-categories {
      height: auto;
      ul {
        height: auto;
        li {
          padding-bottom: 0;
          a {
            padding-left: 5px;
            padding-right: 5px;
            justify-content: center;
            flex-wrap: wrap;
            span {
              border-radius: 50%;
              margin-bottom: 10px;
            }
            strong {
              font-size: 12px;
              width: 100%;
              padding-left: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .listing-container {
    .heading {
      display: none;
      // margin-bottom: 10px;
    }
    .products {
      margin-top: 10px;
      .product-box {
        width: calc(48.5% - 0px);
        margin-right: 5px;
        margin-bottom: 5px;
        padding-left: 7px;
        padding-right: 7px;
        &:nth-child(2n + 2) {
          margin-right: 0px;
        }
        .image {
          height: 130px;
          img {
            max-height: 120px;
          }
        }
        .name {
          min-height: 48px;
          a {
            font-size: 12px;
            line-height: 16px;
          }
        }
        .select {
          select {
            font-size: 12px;
          }
        }
      }
    }
  }
  .categories {
    .MuiContainer-root {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.noproductdiv {
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  color: #1f9151;
  font-size: 16px;
  font-weight: bold;
  height: 100%; /* Ensure it takes the full height of its container */
  margin-top: 80px;
}

@media screen and (max-width:600px) {
  .listing-container{
    .products{
      margin-bottom:60px ;
      margin-right: 4px;
    }
  }
}