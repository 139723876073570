.recently-container {
  display: flex;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0px;
  background-color: #1f915117;

  .heading {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;

    h2 {
      display: inline-flex;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: #1a1a1a;
    }

    button {
      display: inline-flex;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #1f9151;
      text-transform: capitalize;
    }
  }

  .recently-products {
    display: flex;
    width: 100%;

    .product-box {
      display: inline-flex;
      width: calc(33.33% - 20px);
      padding: 15px;
      margin-right: 30px;
      border-radius: 5px;
      border: 1px solid #e6e6e6;
      background-color: #ffffff;

      &:last-child {
        margin-right: 0;
      }

      .image {
        cursor: pointer;
        img {
          max-height: 100px;
        }
      }

      .name {
        display: flex;
        width: 100%;

        a {
          font-size: 13px;
          line-height: 18px;
          color: #535554;
          font-weight: 400;

          &:hover {
            color: #1f9151;
          }
        }
      }

      .price {
        display: inline-flex;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #1a1a1a;
        align-items: center;

        img {
          max-width: 8px;
          height: 12px;
          margin-right: 2px;
        }

        span {
          margin-left: 7px;
          color: #adadad;
          font-weight: 400;
          text-decoration: line-through;
        }
      }

      .ratting {
        display: inline-flex;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #1a1a1a;
        align-items: center;

        svg {
          width: 18px;
          margin-right: 3px;
          color: #ff8a00;
        }
      }

      .buttons {
        display: inline-flex;
        align-items: center;

        button {
          border: 1px solid #e6e6e6;
          border-radius: 50%;
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          padding: 0;
          width: 32px;
          height: 32px;
          min-width: auto;
          margin-left: 10px;

          svg {
            font-size: 18px;
            color: #535554;
          }

          &:hover {
            border-color: #1f9151;

            svg {
              color: #1f9151;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .recently-container {
    .recently-products {
      flex-wrap: wrap;
      align-items: flex-start;
      .product-box {
        width: calc(50% - 30px);
        margin-bottom: 30px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .recently-container {
    .recently-products {
      flex-wrap: wrap;
      align-items: flex-start;
      .product-box {
        width: calc(100% - 0px);
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}