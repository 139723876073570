.header-box {
    display: grid;
    grid-template-columns: 1fr auto; /* Two columns: first for text and second for the button */
    grid-template-rows: auto auto; /* Two rows for the text and subtext */
    gap: 10px;
    align-items: flex-start; /* Align items at the top */
    width: 100%; /* Ensure it uses the full width */
  }
  
  .address-text {
    font-size: 14px;
    font-weight: 600;
  }
  
  .delete-text {
    font-size: 16px;
    font-weight: 600;
  }
  .delete-subtext {
    font-size: 18px;
    width: 210px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
  }


  .address-subtext {
    font-size: 12px;
    color: rgb(88, 86, 86);
    margin-top: 2px; /* Adds space between subtext and text */
    font-weight: 400;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end; /* Aligns the button to the right */
    align-items: center; /* Centers the button vertically */
    grid-column: 2; /* Positions the button in the second column */
    grid-row: span 2; /* Makes sure button spans both rows */
  }
  
  .address-popover button {
    width: 4rem;
    margin: 0;
  }

  .addressbox {
    font-size: 12px;
    height: 6rem; /* Set height */
    background-color: #ade7c6; /* Initially transparent background */
    border-radius: 14px; /* Rounded corners */
    border: 1px solid #dfdada; /* Gray border color */
    padding: 0.8rem; /* Add padding for spacing inside the box */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  .alladdressbox {
    font-size: 12px;
    display: flex;
    flex-direction: column; /* Ensure address components are stacked vertically */
gap: 16px;
  }

  
  .addressmainbox {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    gap: 1rem; /* Adds gap between child elements */  
  }

  .smallbutton{
    background-color: #1f9151;
    height: 32px;
    width: 120px;
    border-radius: 6px;
    font-weight: 500;
    color: #ffffff;
    font-size: 12px;
    box-shadow: none;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  
    &:hover {
      background-color: #1f9151;
      box-shadow: none;
    }
  }
  .address-modal {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 70px; /* Position from the top */
    left: 220px; /* Margin of 4px from the left side */
    z-index: 10; /* Ensures modal stays on top */
    width: 550px; /* Modal width */
    background-color: #ffffff; /* White background */
    box-shadow: 0px 12px 16px 0px #10182814; /* Shadow for depth */
    padding: 0.8rem; /* Internal padding */
    border-radius: 8px; /* Smooth rounded corners */
    height: auto; /* Removed fixed height */
    max-height: 80vh; /* Optional: Limits the height of the modal */
    overflow-y: auto; /* Ensures scrolling if content overflows */
  }

  .address-item {
    background-color: #f8f8f8; /* Light background for contrast */
    padding: 10px;
    color: black;
    border-radius: 14px; /* Rounded corners */
    border: 1px solid #ddd; /* Border for separation */
    width: 100%;
    height: 6rem; 
    cursor: pointer;
    &:hover {
      background-color: #ade7c6;
      box-shadow: none;
      color: black;
    }
  
  }
  
  .address-modal-content {
    padding-left: 20px; /* Adds padding to the left side of the content */
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    gap: 1rem; /* Spacing between child elements */
  }
  
  .address-modal-bg {
    position: fixed; /* Fixed background overlay */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  
 
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
  .css-1xfw5x-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #1F9151 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    text-transform: none !important; /* Ensures no capitalization on selected tab */
  }
  
  .css-1xfw5x-MuiButtonBase-root-MuiTab-root,
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 10px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: #2A3A31 !important; /* Default text color for unselected tabs */
    text-transform: none !important; 
  }
  
  .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: none !important; /* Prevents text transformation on selected tab */
    line-height: 21px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: #1F9151 !important;
  }
  
.newaddressmodal{
  width: 90%;
  max-width: 600px;
  height: auto;
  max-height:82vh ;
  border-radius: 10px;
  padding: 1rem;
  justify-content: center;
  overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; 
}

.deletemodal{
  max-width: 400px;
  width: 90%;
  height: 200px;
  border-radius: 10px;
  padding: 1rem;

  display: flex; /* Enable flexbox */
  flex-direction: column; /* Align items in a column layout */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
gap: 20px;  
}
.buttongap{
  display: flex;
  gap: 10px;
}

.newaddresscontainer{
  width: 320px;
  font-size: 12px;
  height: 10px;
}

.labelform{
  font-size: 12px;  
  height: 84px;
}

.para{
  display: flex;
  font-weight: 600;
  padding: 20px 0 2px 0;
}
.para1{
  color: red;
}

.saveaddressas{
  font-size: 14px;
  font-weight: 600;
  color: #1F9151;
  margin-top: 10px;
}

.icontext{
  color: #1F9151;
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
}

.icontext.selected {
  color: #1f9151;
  font-weight: 600;
}

.iconcontainer{
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.underlinetext {
  color: #1F9151;
  font-size: 12px;
  text-decoration: underline;
  text-underline-offset: 3px; 
  font-weight: 600;
}

.roundDiv {
  width: 60px;            /* Set width */
  height: 20px;           /* Set height */
  border-radius: 10px;    /* Makes the div rounded */
  background-color: #1F9151; /* Green color */
  display: inline-block;  /* Keep it inline with the address text */
  text-align: center;     /* Center the text inside the div */
  color: white;           /* Text color */
  font-size: 10px;        /* Adjust font size */
  line-height: 20px;      /* Vertically center the text */
  margin-left: 4px;   
  font-weight: normal;    /* Ensure text is not bold */
}
.icons {
  cursor: pointer; /* Change the cursor to indicate clickable icon */
  color: gray;
}

.icons:hover {
  opacity: 0.7; /* Add a hover effect for icons */
}

.iconcontainer1{
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.iconcontainer2{
  display: flex;
  gap: 10px;
 }

.smallbutton1{
  color: #1F9151; 
  height: 32px;
  width: 136px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  background-color: white;
  
  &:hover {
    background-color: #cdf1dd;
    box-shadow: none;
  }
}
.viewbutton{
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;

}

.checkbox{
  margin-left: 0;
  font-size: 12px;
margin-right: 0;
}

.addressloader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmbutton{
  background-color: red;
  height: 34px;
  width: 112px;
  border-radius: 4px;
  font-weight: 500;
  color: white;
  font-size: 14px;
  box-shadow: none;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.cancelbutton{
  background-color: #1C1C1C33;
  height: 34px;
  width: 112px;
  border-radius: 4px;
  font-weight: 500;
  color: white;
  font-size: 14px;
  box-shadow: none;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.modalheader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2{
      font-family: Poppins;
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: black;
  }
  img{
      width: 15px;
      cursor: pointer;
  }
}

.checkboxspan{
  font-size: 12px ;
  font-weight: 600;
}


.graydiv{
  border-radius: 10px;    /* Makes the div rounded */
  background-color: #f0eaea; /* Green color */
  text-align: center;     /* Center the text inside the div */
  color: black;           /* Text color */
  font-size: 10px;        /* Adjust font size */
  font-weight: 500;
  justify-content: center;
  border: 0;
  height: 20px;
  width: 80px;
}

.defaultdiv{
  border-radius: 14px;    /* Makes the div rounded */
  background-color: #1f9151; /* Green color */
  text-align: center;     /* Center the text inside the div */
  color: white;           /* Text color */
  font-size: 10px;        /* Adjust font size */
  font-weight: 500;
  justify-content: center;
  border: 0;
  height: 20px;
  width: 70px;
}

.noslot{
  font-size: 14px;
  font-weight: 400;
}



.containerheight{
  height: 70%;
  // background-color: gray;
}



@media screen and (max-width:800px) {
  .btn_parent{
    width: 100%;
  }
  .smallbutton1{
    width: 100%;
  }
   
}



@media screen and (max-width:600px) {
  .address-modal {
    position: fixed; /* Fixed positioning relative to the viewport */
    top: auto; /* Position from the top */
    left: auto; /* Margin of 4px from the left side */
    z-index: 10; /* Ensures modal stays on top */
    width: 100%; /* Modal width */
    background-color: #ffffff; /* White background */
    box-shadow: 0px 12px 16px 0px #10182814; /* Shadow for depth */
    padding: 0.8rem; /* Internal padding */
    border-radius: 8px; /* Smooth rounded corners */
    height: 70vh; /* Removed fixed height */
    max-height: 80vh; /* Optional: Limits the height of the modal */
    overflow-y: auto; /* Ensures scrolling if content overflows */
    bottom: 0;
  }
}

.icons-wrapper{
  cursor: pointer;
}

.button1{
  border: none;
   background-color: white;
  height: 30px;
  cursor: pointer;
}

.roundDiv1 {
  width: 100px;            /* Set width */
  height: 20px;           /* Set height */
  border-radius: 10px;    /* Makes the div rounded */
  background-color: #927c7c; /* Green color */
  display: inline-block;  /* Keep it inline with the address text */
  text-align: center;     /* Center the text inside the div */
  color: white;           /* Text color */
  font-size: 10px;        /* Adjust font size */
  line-height: 20px;      /* Vertically center the text */
  margin-left: 4px;   
  font-weight: normal;    /* Ensure text is not bold */
}

.loaderspacing{
  margin-left: 20px;
  margin-top: 10px;
}